import * as React from "react";
import { dynamicImportWithRetry } from "@fatso83/retry-dynamic-import";

type AsyncRouteComponent<TProps> = React.LazyExoticComponent<
  React.ComponentType<TProps>
>;

export function lazyRouteComponent<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is the only way to infer the props type
  T extends Record<string, any>,
  TKey extends keyof T = "default",
>(
  importer: () => Promise<T>,
  exportName?: TKey,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is the only way to infer the props type
): T[TKey] extends (props: infer TProps) => JSX.Element
  ? AsyncRouteComponent<TProps>
  : never {
  const lazyComp = React.lazy(async () => {
    // HTML Spec requires that dynamically imported module resolution is cached
    // This results in unexpected failures when the cached module no longer exists
    // https://html.spec.whatwg.org/#fetch-a-single-module-script
    // https://stackoverflow.com/questions/72376333/failed-to-fetch-dynamically-imported-module/76200536#76200536
    const moduleExports = await dynamicImportWithRetry(importer);
    const comp = moduleExports[exportName ?? "default"];
    return {
      default: comp,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is the only way to infer the props type
  return lazyComp as any;
}
