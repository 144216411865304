import { Component, type ErrorInfo, type ReactNode } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { ErrorFallback } from "./ErrorFallback";
import { DatadogLoggerContext } from "../Datadog/DatadogLoggerContext";

export interface ErrorBoundaryProps {
  children: React.ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.resetError = this.resetError.bind(this);
    this.state = { hasError: false };
  }

  static contextType = DatadogLoggerContext;
  declare context: React.ContextType<typeof DatadogLoggerContext>;

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const errorMessage = `[Page Crash] ${error.message}`;
    if (this.context) {
      this.context.logError(errorMessage, errorInfo);
    } else {
      datadogLogs.logger.error(errorMessage, errorInfo);
    }
  }

  resetError(): void {
    this.setState({
      hasError: false,
    });
    window.location.reload();
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <ErrorFallback onRetry={this.resetError} />;
    }
    return children;
  }
}
