import { AtlantisThemeContextProvider } from "@jobber/components";
import { Fragment, StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { DatadogErrorBoundaryProvider } from "~/utilities/errors/Datadog/DatadogErrorBoundaryProvider";
import { VITE_ENTRYPOINT } from "~/utilities/errors/Datadog/loggerNames";

interface TReactIslandConfig<TLazyIsland> extends TIslandOptions {
  Component: TLazyIsland;
}

interface TRegisterIsland<TLazyIsland> {
  name: string;
  Component: TLazyIsland;
}

/**
 * Additional options for registering an island component
 * @param unsafe__disableReactStrictMode - Disables React.StrictMode
 */
interface TIslandOptions {
  unsafe__disableReactStrictMode?: boolean;
}

function createIslandRegistry<
  TIsland extends React.ComponentType,
  TLazyIsland = React.LazyExoticComponent<TIsland>,
>() {
  const islands = new Map<string, TReactIslandConfig<TLazyIsland>>();

  function defineElement() {
    customElements.get("react-island") ||
      customElements.define("react-island", ReactIslandElement, {
        extends: "div",
      });
  }

  function getIslandSafe(name: string) {
    return islands.get(name);
  }

  function getIsland(name: string) {
    const islandComponent = getIslandSafe(name);

    if (!islandComponent) {
      throw new Error(`Island '${name}' not found.`);
    }

    return islandComponent;
  }

  function registerIsland({
    name,
    Component,
    unsafe__disableReactStrictMode = false,
  }: TRegisterIsland<TLazyIsland> & TIslandOptions) {
    if (getIslandSafe(name)) {
      throw new Error("Island already registered");
    }

    islands.set(name, {
      unsafe__disableReactStrictMode,
      Component,
    });
    return registryActions;
  }

  function reset() {
    islands.clear();
    return registryActions;
  }

  const registryActions = {
    islands,
    getIsland,
    registerIsland,
    reset,
    defineElement,
  };

  return registryActions;
}

export const registry = createIslandRegistry();

export class ReactIslandElement extends HTMLDivElement {
  private root: ReactDOM.Root | null = null;

  constructor() {
    super();
  }

  connectedCallback() {
    const Island = this.island?.Component;

    if (Island) {
      this.root = ReactDOM.createRoot(this);
      const loggerName = `${VITE_ENTRYPOINT}-${this.name}`;

      const OverrideStrictMode = this.island?.unsafe__disableReactStrictMode
        ? Fragment
        : StrictMode;

      this.root.render(
        <OverrideStrictMode>
          <DatadogErrorBoundaryProvider loggerName={loggerName}>
            <Suspense fallback={<></>}>
              <AtlantisThemeContextProvider>
                <Island {...this.initialProps} />
              </AtlantisThemeContextProvider>
            </Suspense>
          </DatadogErrorBoundaryProvider>
        </OverrideStrictMode>,
      );
    } else {
      console.warn(`Could not resolve island with name: '${this.name}'`);
    }
  }

  disconnectedCallback() {
    if (this.root) {
      this.root.unmount();
    }
  }

  get island() {
    return registry.getIsland(this.name || "");
  }

  get name() {
    return this.dataset.name;
  }

  get initialProps() {
    return JSON.parse(this.dataset.props || "{}");
  }
}
