import { Banner } from "@jobber/components/Banner";

interface ErrorFallbackProps {
  onRetry(): void;
}

export function ErrorFallback({ onRetry }: ErrorFallbackProps) {
  const handleRetry = () => {
    onRetry();
  };

  return (
    <Banner
      type={"error"}
      primaryAction={{
        label: "Refresh",
        onClick: handleRetry,
      }}
      dismissible={false}
      icon="alert"
    >
      Something went wrong. Refresh to try again.
    </Banner>
  );
}
