import { lazyRouteComponent } from "~/utilities/vite/lazyRouteComponent";
import { registry } from "~/utilities/vite/reactIslandRegistry";

registry.registerIsland({
  name: "shared/payments/components/AuthorizeNet",
  Component: lazyRouteComponent(
    () => import("~/shared/payments/components/AuthorizeNet"),
    "AuthorizeNet",
  ),
});

registry.registerIsland({
  name: "shared/fileAttachmentList/components/FileAttachmentList",
  Component: lazyRouteComponent(
    () => import("~/shared/fileAttachmentList/components/FileAttachmentList"),
    "FileAttachmentList",
  ),
});

registry.registerIsland({
  name: "shared/BannerListener/BannerListener",
  Component: lazyRouteComponent(
    () => import("~/shared/BannerListener/BannerListener"),
    "BannerListener",
  ),
});

registry.registerIsland({
  name: "shared/propertyAutocompleteWrapper/components/PropertyAutocompleteWrapper",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/shared/propertyAutocompleteWrapper/components/PropertyAutocompleteWrapper"
      ),
    "PropertyAutocompleteWrapper",
  ),
});

registry.registerIsland({
  name: "shared/fileAttachmentPreview/FileAttachmentPreview",
  Component: lazyRouteComponent(
    () => import("~/shared/fileAttachmentPreview/FileAttachmentPreview"),
    "FileAttachmentPreview",
  ),
});

registry.registerIsland({
  name: "shared/QuoteLayout/DisplaySlot",
  Component: lazyRouteComponent(
    () => import("~/shared/QuoteLayout/DisplaySlot/DisplaySlot"),
    "DisplaySlot",
  ),
});

registry.registerIsland({
  name: "shared/SalespersonField/SalespersonFieldWrapper",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/shared/SalespersonField/SalespersonFieldWrapper/SalespersonFieldWrapper"
      ),
    "SalespersonFieldWrapper",
  ),
});

registry.registerIsland({
  name: "shared/Central/Pages/Home/Dashboard",
  Component: lazyRouteComponent(
    () => import("~/shared/Central/Pages/Home/Dashboard"),
    "Dashboard",
  ),
});
