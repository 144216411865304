import { useContext } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { JOBBER_ONLINE_LOGGER } from "./constants";
import type { DatadogLoggerContextProviderProps } from "./types";
import { DatadogLoggerContext } from "./DatadogLoggerContext";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

function DatadogErrorBoundaryProvider({
  children,
  loggerName = JOBBER_ONLINE_LOGGER,
  loggerConfiguration,
}: DatadogLoggerContextProviderProps) {
  const datadogLogger = datadogLogs.createLogger(
    loggerName,
    loggerConfiguration,
  );

  const logError = (
    message: string,
    messageContext?: object,
    error?: Error,
  ): void => {
    datadogLogger?.error(message, messageContext, error);
  };

  const logInfo = (
    message: string,
    messageContext?: object,
    error?: Error,
  ): void => {
    datadogLogger?.info(message, messageContext, error);
  };

  return (
    <DatadogLoggerContext.Provider value={{ logError, logInfo }}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </DatadogLoggerContext.Provider>
  );
}

const useDatadogLogger = () => {
  const context = useContext(DatadogLoggerContext);

  if (!context) {
    throw new Error(
      "useDatadogLogger must be used within a DatadogLoggerContextProvider",
    );
  }

  return context;
};

export { DatadogErrorBoundaryProvider, useDatadogLogger };
